import React from 'react'
import NavigationTabs from 'components/NavigationTabs'
import WaitForData from 'components/WaitForData'

import MultiLanguage from './MultiLanguage'
import Triggers from './Triggers'

export default function Settings({ account, store }) {
  return (
    <WaitForData
      objects={[account, store]}
      loadingMessage="Fetching account settings"
      errorMessage="Trouble fetching account data"
    >
      {() => (
        <div className="flex flex-col items-stretch flex-1 mb-56">
          <div className="flex flex-row flex-wrap items-center mb-6">
            <h3 className="text-sm font-bold text-gray-800 uppercase">
              Settings
            </h3>
          </div>
          <NavigationTabs
            tabs={TABS}
            visibilityCheckParams={[account.data]}
            tabProps={{
              account,
              store,
            }}
          />
        </div>
      )}
    </WaitForData>
  )
}

const TABS = [
  {
    label: 'Multi Language',
    value: 'translations',
    component: MultiLanguage,
    root: true,
  },
  // {
  //   label: 'Manual Triggers',
  //   value: 'manual-triggers',
  //   tooltip:
  //     'Trigger actions such as product sync or regeneration of recommendations from here.',
  //   component: Triggers,
  // },
]
