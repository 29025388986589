import React, { useEffect, useState } from 'react'

import Box from 'components/Box'
import Icons from 'components/Icons'
import Button from 'components/Button'
import LANGUAGES from 'constants/languages'
import CodeEditor from 'components/CodeEditor'

export default function TemplateTranslations({
  template,
  onSave,
  isSaving,
  openUpgradeAccount,
}) {
  const translations = getTranslationsFromTemplateData(template)
  const updateTranslationsDisabled =
    openUpgradeAccount && typeof openUpgradeAccount === 'function'
  const [value, setValue] = useState(JSON.stringify(translations, null, 2))
  async function onSubmit(data) {
    if (openUpgradeAccount) {
      openUpgradeAccount()
    } else {
      let json = {}
      try {
        json = JSON.parse(data)
      } catch (e) {
        alert('Invalid JSON')
        return
      }
      onSave({
        translations: json,
      })
    }
  }

  return (
    <div className="flex flex-col items-stretch">
      <Box>
        <CodeEditor
          name={'Translations'}
          height="50vh"
          value={value}
          onChange={setValue}
          mode="json"
          readOnly={false}
        />
        <Button
          loading={isSaving}
          onClick={() => {
            onSubmit(value)
          }}
        >
          {' '}
          <div className="flex items-center justify-center">
            <span>Save</span>
            {updateTranslationsDisabled && (
              <Icons.Lock className="w-4 ml-2 opacity-75 fill-current" />
            )}
          </div>
        </Button>
      </Box>
    </div>
  )
}

function getTranslationsFromTemplateData(template) {
  const defaults = LANGUAGES.reduce(
    (obj, lang) => ({
      ...obj,
      [lang.value]: {},
    }),
    {}
  )

  return {
    ...defaults,
    ...template.translations,
  }
}
